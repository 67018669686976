import { default as activitiesDkjIh4lkQLMeta } from "/usr/src/app/pages/activities.vue?macro=true";
import { default as settingsze1xDUhiuBMeta } from "/usr/src/app/pages/admin-settings/activities/settings.vue?macro=true";
import { default as activitiesvWj1JvS1dcMeta } from "/usr/src/app/pages/admin-settings/activities.vue?macro=true";
import { default as filtersUlTm1lqm5iMeta } from "/usr/src/app/pages/admin-settings/leads/filters.vue?macro=true";
import { default as sdrtok8NxvyxKMeta } from "/usr/src/app/pages/admin-settings/leads/sdr.vue?macro=true";
import { default as settingsagX8HlbGH2Meta } from "/usr/src/app/pages/admin-settings/leads/settings.vue?macro=true";
import { default as leadssGL2oWhIeoMeta } from "/usr/src/app/pages/admin-settings/leads.vue?macro=true";
import { default as settingsfNBbYxfS0sMeta } from "/usr/src/app/pages/admin-settings/notifications/settings.vue?macro=true";
import { default as templateswpmtvfcGHlMeta } from "/usr/src/app/pages/admin-settings/notifications/templates.vue?macro=true";
import { default as notificationsQrQpkcx7TyMeta } from "/usr/src/app/pages/admin-settings/notifications.vue?macro=true";
import { default as marketersCwaaIEA5KuMeta } from "/usr/src/app/pages/admin-settings/people/marketers.vue?macro=true";
import { default as permissionsh7xIz0ojIBMeta } from "/usr/src/app/pages/admin-settings/people/permissions.vue?macro=true";
import { default as teams2ir2zM1qvuMeta } from "/usr/src/app/pages/admin-settings/people/teams.vue?macro=true";
import { default as user_45permissionsWQmgvM8KBgMeta } from "/usr/src/app/pages/admin-settings/people/user-permissions.vue?macro=true";
import { default as archiveq2ugV043NKMeta } from "/usr/src/app/pages/admin-settings/people/users/archive.vue?macro=true";
import { default as inactiveu1Ad61r1SFMeta } from "/usr/src/app/pages/admin-settings/people/users/inactive.vue?macro=true";
import { default as indexzbEGZqqetOMeta } from "/usr/src/app/pages/admin-settings/people/users/index.vue?macro=true";
import { default as peopleEFWtl84rqyMeta } from "/usr/src/app/pages/admin-settings/people.vue?macro=true";
import { default as releaseR3gkg3K9ZuMeta } from "/usr/src/app/pages/admin-settings/release.vue?macro=true";
import { default as usersxSURYjghENMeta } from "/usr/src/app/pages/admin-settings/tech-logs/users.vue?macro=true";
import { default as tech_45logsZoi5frGItiMeta } from "/usr/src/app/pages/admin-settings/tech-logs.vue?macro=true";
import { default as locations_45numbersxtk7QdjrRvMeta } from "/usr/src/app/pages/admin-settings/telephony/locations-numbers.vue?macro=true";
import { default as settingsMnh32kXn6bMeta } from "/usr/src/app/pages/admin-settings/telephony/settings.vue?macro=true";
import { default as telephonyzKhGctGivdMeta } from "/usr/src/app/pages/admin-settings/telephony.vue?macro=true";
import { default as admin_45settingsQE9lZPgAJUMeta } from "/usr/src/app/pages/admin-settings.vue?macro=true";
import { default as callsiP4eAMp9vVMeta } from "/usr/src/app/pages/dashboard/calls.vue?macro=true";
import { default as campaignsi4Ps1sJwfdMeta } from "/usr/src/app/pages/dashboard/campaigns.vue?macro=true";
import { default as indexRKWbHmLmj6Meta } from "/usr/src/app/pages/dashboard/index.vue?macro=true";
import { default as _91id_93doVzlHOBsVMeta } from "/usr/src/app/pages/deals/[id].vue?macro=true";
import { default as archiveU4bIFeZLf1Meta } from "/usr/src/app/pages/deals/all/archive.vue?macro=true";
import { default as currentC64Ckxe2oXMeta } from "/usr/src/app/pages/deals/all/current.vue?macro=true";
import { default as all9jXeO8FrVHMeta } from "/usr/src/app/pages/deals/all.vue?macro=true";
import { default as callbackC7q2dfMa4wMeta } from "/usr/src/app/pages/google/calendars/callback.vue?macro=true";
import { default as error_45callbackOaH1BOesHKMeta } from "/usr/src/app/pages/google/calendars/error-callback.vue?macro=true";
import { default as messagespfGVGYkfkfMeta } from "/usr/src/app/pages/inbox/email/messages.vue?macro=true";
import { default as templatesLkMzAnnX11Meta } from "/usr/src/app/pages/inbox/email/templates.vue?macro=true";
import { default as messagespBY2f1Iy7OMeta } from "/usr/src/app/pages/inbox/whatsapp/messages.vue?macro=true";
import { default as templatesH9RtsCKLgDMeta } from "/usr/src/app/pages/inbox/whatsapp/templates.vue?macro=true";
import { default as inboxpSrPs2vNOJMeta } from "/usr/src/app/pages/inbox.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as activitytHUwgdVkM3Meta } from "/usr/src/app/pages/leads/[id]/activity.vue?macro=true";
import { default as assigng5uqjHjfMMMeta } from "/usr/src/app/pages/leads/[id]/assign.vue?macro=true";
import { default as _91call_45id_93oFpWx09yllMeta } from "/usr/src/app/pages/leads/[id]/call/[call-id].vue?macro=true";
import { default as indexlhNfg7JXSsMeta } from "/usr/src/app/pages/leads/[id]/index.vue?macro=true";
import { default as credentialsxkLgT7TvBzMeta } from "/usr/src/app/pages/login/credentials.vue?macro=true";
import { default as indexMqL0VaR3piMeta } from "/usr/src/app/pages/login/index.vue?macro=true";
import { default as twoFAM3k6nqzD4uMeta } from "/usr/src/app/pages/login/twoFA.vue?macro=true";
import { default as all_45leadsUC9CCWVtTrMeta } from "/usr/src/app/pages/pipelines/all-leads.vue?macro=true";
import { default as archiveSpZ5Gt0Te9Meta } from "/usr/src/app/pages/pipelines/archive.vue?macro=true";
import { default as assign_45logsEOsrsH9bDgMeta } from "/usr/src/app/pages/pipelines/assign-logs.vue?macro=true";
import { default as call_45logsuozmJuBeq1Meta } from "/usr/src/app/pages/pipelines/call-logs.vue?macro=true";
import { default as call_45queue1ZwS05gCW8Meta } from "/usr/src/app/pages/pipelines/call-queue.vue?macro=true";
import { default as poolpuk46EUyyDMeta } from "/usr/src/app/pages/pipelines/pool.vue?macro=true";
import { default as queuejSfIhwH7HyMeta } from "/usr/src/app/pages/pipelines/queue.vue?macro=true";
import { default as sales55bdFwMmIEMeta } from "/usr/src/app/pages/pipelines/sales.vue?macro=true";
import { default as pipelinesNo50ClglpiMeta } from "/usr/src/app/pages/pipelines.vue?macro=true";
import { default as setupGVXTFRNx82Meta } from "/usr/src/app/pages/profile/setup.vue?macro=true";
import { default as welcomeTkmG2DKNlfMeta } from "/usr/src/app/pages/profile/welcome.vue?macro=true";
import { default as _91id_93vcUPitdGkyMeta } from "/usr/src/app/pages/properties/[id].vue?macro=true";
import { default as all9wJxf1tOmcMeta } from "/usr/src/app/pages/properties/all.vue?macro=true";
import { default as exclusivesusvTtFPSvfMeta } from "/usr/src/app/pages/properties/exclusives.vue?macro=true";
import { default as properties5I0VgbXTlXMeta } from "/usr/src/app/pages/properties.vue?macro=true";
import { default as indexyryyYDnZgJMeta } from "/usr/src/app/pages/property/[hash]/index.vue?macro=true";
import { default as _91id_93gxE6VCJUHVMeta } from "/usr/src/app/pages/property/[hash]/unit/[id].vue?macro=true";
import { default as code4zu4Xf2HoHMeta } from "/usr/src/app/pages/register/code.vue?macro=true";
import { default as emailbaRUM9Unl3Meta } from "/usr/src/app/pages/register/email.vue?macro=true";
import { default as indexPceEpwfy5dMeta } from "/usr/src/app/pages/register/index.vue?macro=true";
import { default as passwordEn6A9BQiDqMeta } from "/usr/src/app/pages/register/password.vue?macro=true";
import { default as coder6J7Om1inkMeta } from "/usr/src/app/pages/reset/code.vue?macro=true";
import { default as indexTZHXTk2gM2Meta } from "/usr/src/app/pages/reset/index.vue?macro=true";
import { default as newMG3wLaVrsHMeta } from "/usr/src/app/pages/reset/new.vue?macro=true";
import { default as customYSn8H0lv0WMeta } from "/usr/src/app/pages/rotations/custom.vue?macro=true";
import { default as data_45enrichmentoF4NCeBpzxMeta } from "/usr/src/app/pages/rotations/data-enrichment.vue?macro=true";
import { default as defaultT3EqfGPJvQMeta } from "/usr/src/app/pages/rotations/default.vue?macro=true";
import { default as mappingsyCaQTHa54zMeta } from "/usr/src/app/pages/rotations/mappings.vue?macro=true";
import { default as settingsXySczVVAFPMeta } from "/usr/src/app/pages/rotations/settings.vue?macro=true";
import { default as rotationshN7Tle9X0UMeta } from "/usr/src/app/pages/rotations.vue?macro=true";
import { default as indexPhr4sKDF2rMeta } from "/usr/src/app/pages/settings/index.vue?macro=true";
import { default as callback2SLl5yLrKYMeta } from "/usr/src/app/pages/sso/callback.vue?macro=true";
import { default as _91id_93huwIyHFRGJMeta } from "/usr/src/app/pages/users/[id].vue?macro=true";
export default [
  {
    name: "activities",
    path: "/activities",
    meta: activitiesDkjIh4lkQLMeta || {},
    component: () => import("/usr/src/app/pages/activities.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings",
    path: "/admin-settings",
    meta: admin_45settingsQE9lZPgAJUMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-activities",
    path: "activities",
    meta: activitiesvWj1JvS1dcMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/activities.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-activities-settings",
    path: "settings",
    component: () => import("/usr/src/app/pages/admin-settings/activities/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-settings-leads",
    path: "leads",
    meta: leadssGL2oWhIeoMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/leads.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-leads-filters",
    path: "filters",
    meta: filtersUlTm1lqm5iMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/leads/filters.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-leads-sdr",
    path: "sdr",
    meta: sdrtok8NxvyxKMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/leads/sdr.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-leads-settings",
    path: "settings",
    meta: settingsagX8HlbGH2Meta || {},
    component: () => import("/usr/src/app/pages/admin-settings/leads/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-settings-notifications",
    path: "notifications",
    meta: notificationsQrQpkcx7TyMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/notifications.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-notifications-settings",
    path: "settings",
    component: () => import("/usr/src/app/pages/admin-settings/notifications/settings.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-notifications-templates",
    path: "templates",
    meta: templateswpmtvfcGHlMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/notifications/templates.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-settings-people",
    path: "people",
    meta: peopleEFWtl84rqyMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-people-marketers",
    path: "marketers",
    meta: marketersCwaaIEA5KuMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/marketers.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-people-permissions",
    path: "permissions",
    meta: permissionsh7xIz0ojIBMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/permissions.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-people-teams",
    path: "teams",
    meta: teams2ir2zM1qvuMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/teams.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-people-user-permissions",
    path: "user-permissions",
    meta: user_45permissionsWQmgvM8KBgMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/user-permissions.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-people-users-archive",
    path: "users/archive",
    meta: archiveq2ugV043NKMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/users/archive.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-people-users-inactive",
    path: "users/inactive",
    meta: inactiveu1Ad61r1SFMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/users/inactive.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-people-users",
    path: "users",
    meta: indexzbEGZqqetOMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/people/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-settings-release",
    path: "release",
    meta: releaseR3gkg3K9ZuMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/release.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-tech-logs",
    path: "tech-logs",
    meta: tech_45logsZoi5frGItiMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/tech-logs.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-tech-logs-users",
    path: "users",
    meta: usersxSURYjghENMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/tech-logs/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-settings-telephony",
    path: "telephony",
    meta: telephonyzKhGctGivdMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/telephony.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-settings-telephony-locations-numbers",
    path: "locations-numbers",
    meta: locations_45numbersxtk7QdjrRvMeta || {},
    component: () => import("/usr/src/app/pages/admin-settings/telephony/locations-numbers.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings-telephony-settings",
    path: "settings",
    component: () => import("/usr/src/app/pages/admin-settings/telephony/settings.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "dashboard-calls",
    path: "/dashboard/calls",
    meta: callsiP4eAMp9vVMeta || {},
    component: () => import("/usr/src/app/pages/dashboard/calls.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-campaigns",
    path: "/dashboard/campaigns",
    meta: campaignsi4Ps1sJwfdMeta || {},
    component: () => import("/usr/src/app/pages/dashboard/campaigns.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexRKWbHmLmj6Meta || {},
    component: () => import("/usr/src/app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "deals-id",
    path: "/deals/:id()",
    meta: _91id_93doVzlHOBsVMeta || {},
    component: () => import("/usr/src/app/pages/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: "deals-all",
    path: "/deals/all",
    meta: all9jXeO8FrVHMeta || {},
    component: () => import("/usr/src/app/pages/deals/all.vue").then(m => m.default || m),
    children: [
  {
    name: "deals-all-archive",
    path: "archive",
    meta: archiveU4bIFeZLf1Meta || {},
    component: () => import("/usr/src/app/pages/deals/all/archive.vue").then(m => m.default || m)
  },
  {
    name: "deals-all-current",
    path: "current",
    meta: currentC64Ckxe2oXMeta || {},
    component: () => import("/usr/src/app/pages/deals/all/current.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "google-calendars-callback",
    path: "/google/calendars/callback",
    meta: callbackC7q2dfMa4wMeta || {},
    component: () => import("/usr/src/app/pages/google/calendars/callback.vue").then(m => m.default || m)
  },
  {
    name: "google-calendars-error-callback",
    path: "/google/calendars/error-callback",
    meta: error_45callbackOaH1BOesHKMeta || {},
    component: () => import("/usr/src/app/pages/google/calendars/error-callback.vue").then(m => m.default || m)
  },
  {
    name: "inbox",
    path: "/inbox",
    meta: inboxpSrPs2vNOJMeta || {},
    component: () => import("/usr/src/app/pages/inbox.vue").then(m => m.default || m),
    children: [
  {
    name: "inbox-email-messages",
    path: "email/messages",
    component: () => import("/usr/src/app/pages/inbox/email/messages.vue").then(m => m.default || m)
  },
  {
    name: "inbox-email-templates",
    path: "email/templates",
    component: () => import("/usr/src/app/pages/inbox/email/templates.vue").then(m => m.default || m)
  },
  {
    name: "inbox-whatsapp-messages",
    path: "whatsapp/messages",
    component: () => import("/usr/src/app/pages/inbox/whatsapp/messages.vue").then(m => m.default || m)
  },
  {
    name: "inbox-whatsapp-templates",
    path: "whatsapp/templates",
    component: () => import("/usr/src/app/pages/inbox/whatsapp/templates.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "leads-id-activity",
    path: "/leads/:id()/activity",
    meta: activitytHUwgdVkM3Meta || {},
    component: () => import("/usr/src/app/pages/leads/[id]/activity.vue").then(m => m.default || m)
  },
  {
    name: "leads-id-assign",
    path: "/leads/:id()/assign",
    meta: assigng5uqjHjfMMMeta || {},
    component: () => import("/usr/src/app/pages/leads/[id]/assign.vue").then(m => m.default || m)
  },
  {
    name: "leads-id-call-callid",
    path: "/leads/:id()/call/:callid()",
    component: () => import("/usr/src/app/pages/leads/[id]/call/[call-id].vue").then(m => m.default || m)
  },
  {
    name: "leads-id",
    path: "/leads/:id()",
    meta: indexlhNfg7JXSsMeta || {},
    component: () => import("/usr/src/app/pages/leads/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "login-credentials",
    path: "/login/credentials",
    meta: credentialsxkLgT7TvBzMeta || {},
    component: () => import("/usr/src/app/pages/login/credentials.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexMqL0VaR3piMeta || {},
    component: () => import("/usr/src/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-twoFA",
    path: "/login/twoFA",
    meta: twoFAM3k6nqzD4uMeta || {},
    component: () => import("/usr/src/app/pages/login/twoFA.vue").then(m => m.default || m)
  },
  {
    name: "pipelines",
    path: "/pipelines",
    meta: pipelinesNo50ClglpiMeta || {},
    component: () => import("/usr/src/app/pages/pipelines.vue").then(m => m.default || m),
    children: [
  {
    name: "pipelines-all-leads",
    path: "all-leads",
    meta: all_45leadsUC9CCWVtTrMeta || {},
    component: () => import("/usr/src/app/pages/pipelines/all-leads.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-archive",
    path: "archive",
    meta: archiveSpZ5Gt0Te9Meta || {},
    component: () => import("/usr/src/app/pages/pipelines/archive.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-assign-logs",
    path: "assign-logs",
    meta: assign_45logsEOsrsH9bDgMeta || {},
    component: () => import("/usr/src/app/pages/pipelines/assign-logs.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-call-logs",
    path: "call-logs",
    meta: call_45logsuozmJuBeq1Meta || {},
    component: () => import("/usr/src/app/pages/pipelines/call-logs.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-call-queue",
    path: "call-queue",
    meta: call_45queue1ZwS05gCW8Meta || {},
    component: () => import("/usr/src/app/pages/pipelines/call-queue.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-pool",
    path: "pool",
    meta: poolpuk46EUyyDMeta || {},
    component: () => import("/usr/src/app/pages/pipelines/pool.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-queue",
    path: "queue",
    meta: queuejSfIhwH7HyMeta || {},
    component: () => import("/usr/src/app/pages/pipelines/queue.vue").then(m => m.default || m)
  },
  {
    name: "pipelines-sales",
    path: "sales",
    meta: sales55bdFwMmIEMeta || {},
    component: () => import("/usr/src/app/pages/pipelines/sales.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profile-setup",
    path: "/profile/setup",
    meta: setupGVXTFRNx82Meta || {},
    component: () => import("/usr/src/app/pages/profile/setup.vue").then(m => m.default || m)
  },
  {
    name: "profile-welcome",
    path: "/profile/welcome",
    meta: welcomeTkmG2DKNlfMeta || {},
    component: () => import("/usr/src/app/pages/profile/welcome.vue").then(m => m.default || m)
  },
  {
    name: "properties",
    path: "/properties",
    meta: properties5I0VgbXTlXMeta || {},
    component: () => import("/usr/src/app/pages/properties.vue").then(m => m.default || m),
    children: [
  {
    name: "properties-id",
    path: ":id()",
    meta: _91id_93vcUPitdGkyMeta || {},
    component: () => import("/usr/src/app/pages/properties/[id].vue").then(m => m.default || m)
  },
  {
    name: "properties-all",
    path: "all",
    meta: all9wJxf1tOmcMeta || {},
    component: () => import("/usr/src/app/pages/properties/all.vue").then(m => m.default || m)
  },
  {
    name: "properties-exclusives",
    path: "exclusives",
    component: () => import("/usr/src/app/pages/properties/exclusives.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "property-hash",
    path: "/property/:hash()",
    meta: indexyryyYDnZgJMeta || {},
    component: () => import("/usr/src/app/pages/property/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: "property-hash-unit-id",
    path: "/property/:hash()/unit/:id()",
    meta: _91id_93gxE6VCJUHVMeta || {},
    component: () => import("/usr/src/app/pages/property/[hash]/unit/[id].vue").then(m => m.default || m)
  },
  {
    name: "register-code",
    path: "/register/code",
    meta: code4zu4Xf2HoHMeta || {},
    component: () => import("/usr/src/app/pages/register/code.vue").then(m => m.default || m)
  },
  {
    name: "register-email",
    path: "/register/email",
    meta: emailbaRUM9Unl3Meta || {},
    component: () => import("/usr/src/app/pages/register/email.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: indexPceEpwfy5dMeta || {},
    component: () => import("/usr/src/app/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "register-password",
    path: "/register/password",
    meta: passwordEn6A9BQiDqMeta || {},
    component: () => import("/usr/src/app/pages/register/password.vue").then(m => m.default || m)
  },
  {
    name: "reset-code",
    path: "/reset/code",
    meta: coder6J7Om1inkMeta || {},
    component: () => import("/usr/src/app/pages/reset/code.vue").then(m => m.default || m)
  },
  {
    name: "reset",
    path: "/reset",
    meta: indexTZHXTk2gM2Meta || {},
    component: () => import("/usr/src/app/pages/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-new",
    path: "/reset/new",
    meta: newMG3wLaVrsHMeta || {},
    component: () => import("/usr/src/app/pages/reset/new.vue").then(m => m.default || m)
  },
  {
    name: "rotations",
    path: "/rotations",
    meta: rotationshN7Tle9X0UMeta || {},
    component: () => import("/usr/src/app/pages/rotations.vue").then(m => m.default || m),
    children: [
  {
    name: "rotations-custom",
    path: "custom",
    meta: customYSn8H0lv0WMeta || {},
    component: () => import("/usr/src/app/pages/rotations/custom.vue").then(m => m.default || m)
  },
  {
    name: "rotations-data-enrichment",
    path: "data-enrichment",
    meta: data_45enrichmentoF4NCeBpzxMeta || {},
    component: () => import("/usr/src/app/pages/rotations/data-enrichment.vue").then(m => m.default || m)
  },
  {
    name: "rotations-default",
    path: "default",
    meta: defaultT3EqfGPJvQMeta || {},
    component: () => import("/usr/src/app/pages/rotations/default.vue").then(m => m.default || m)
  },
  {
    name: "rotations-mappings",
    path: "mappings",
    meta: mappingsyCaQTHa54zMeta || {},
    component: () => import("/usr/src/app/pages/rotations/mappings.vue").then(m => m.default || m)
  },
  {
    name: "rotations-settings",
    path: "settings",
    meta: settingsXySczVVAFPMeta || {},
    component: () => import("/usr/src/app/pages/rotations/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexPhr4sKDF2rMeta || {},
    component: () => import("/usr/src/app/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "sso-callback",
    path: "/sso/callback",
    meta: callback2SLl5yLrKYMeta || {},
    component: () => import("/usr/src/app/pages/sso/callback.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93huwIyHFRGJMeta || {},
    component: () => import("/usr/src/app/pages/users/[id].vue").then(m => m.default || m)
  }
]