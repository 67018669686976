<template>
  <div class="relative overflow-y-auto">
    <Transition name="fade">
      <div
        v-if="showTopOverflowBorder"
        class="absolute -top-1 h-1 w-full border-b border-solid border-black-10 bg-transparent"
        :class="{ 'shadow-4': showTopOverflowBorder }"
      />
    </Transition>

    <div
      ref="detailsRef"
      class="flex h-full flex-col gap-4 overflow-y-auto p-8 2xl:p-12 2xl:pt-8"
      @scroll="checkScroll"
    >
      <UiExpansionPanel v-model="stagePanel" title="Lead stage">
        <div class="mb-5 flex flex-row items-center justify-between">
          <LeadInfoStage
            v-model="lead.pipeline_stage_id"
            :lead="lead"
            :readonly="readonly || limitedAccess"
            @update:model-value="$emit('update:modelValue', lead)"
          />
          <UiButtonBase
            v-if="isClosedWon && !isSDRTourActive"
            id="create-deal"
            class="text-subhead-3 !rounded-lg"
            size="small"
            type="primary"
            @click="createDealForClosed"
          >
            Create Deal
          </UiButtonBase>
          <UiButtonBase
            v-if="limitedAccess && !isSDRTourActive"
            id="return-to-queue"
            size="small"
            @click="returnToQueue"
            >Return lead to Queue</UiButtonBase
          >
          <div
            v-if="(!readonly && !isClosed && !limitedAccess) || isSDRTourActive"
            id="won_lost_buttons"
            class="flex flex-row items-center gap-2"
          >
            <UiButtonBase
              v-if="(authStore.getIsSdr && !isPoolLead) || isSDRTourActive"
              id="qualify"
              size="small"
              type="success"
              @click="showAssignAgentPopup"
              >Qualify
            </UiButtonBase>
            <UiButtonBase
              v-else-if="!isPoolLead || (isSDRTourActive && authStore.getIsSdr)"
              id="won"
              size="small"
              type="success"
              @click="showCloseLeadPopup(true)"
              >Won
            </UiButtonBase>
            <UiButtonBase id="lost" size="small" type="error" @click="showCloseLeadPopup(false)"> Lost </UiButtonBase>
          </div>
          <div v-if="isClosedLost" class="flex flex-row items-center">
            <UiButtonBase id="reopen" class="text-subhead-3" type="primary" size="small" @click="emits('reopen')">
              Reopen and assign
            </UiButtonBase>
          </div>
        </div>
      </UiExpansionPanel>
      <UiExpansionPanel
        v-if="isContactsBlockVisible"
        v-model="contactsPanel"
        title="Lead contacts"
        :icon="!!readonly || isClosed ? '' : 'edit'"
        @action="showEditDetailsSidePanel"
      >
        <LeadInfoDetailsPhoneNumber
          v-if="isCommunicationWayPartner && lead.partner"
          :phone="lead.partner.phone"
          :phone-country="lead.partner.phone_country"
          :lead
          class="mb-6"
          label="Partner phone number primary"
        />
        <LeadInfoDetailsPhoneNumber
          v-else-if="isCommunicationWayRepresentative && lead.representative"
          :lead
          :phone="lead.representative.phone"
          :phone-country="lead.representative.phone_country"
          class="mb-6"
          label="Representative phone number primary"
        />
        <LeadInfoDetailsPhoneNumber
          :lead
          :phone="lead.phone"
          :phone-country="lead.phone_country"
          class="mb-5"
          :label="
            isCommunicationWayPartner || isCommunicationWayRepresentative
              ? 'Lead phone number secondary'
              : 'Phone number primary'
          "
        />
      </UiExpansionPanel>
      <UiExpansionPanel
        v-model="detailsPanel"
        title="Lead details"
        :icon="!!readonly || isClosed ? '' : 'edit'"
        @action="showEditDetailsSidePanel"
      >
        <div class="mb-5 flex flex-col gap-3">
          <div v-if="lead.email" class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Email </small>
            <small class="w-2/3 text-black-100" :class="{ 'blur-sm': modelValue.email === HIDDEN }">
              {{ modelValue.email || '-' }}
            </small>
          </div>
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Country </small>
            <small class="w-2/3 text-black-100">
              {{ modelValue.country?.name || '-' }}
            </small>
          </div>
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Language </small>
            <small class="w-2/3 text-black-100">
              {{ modelValue.language?.name || '-' }}
            </small>
          </div>
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Local time </small>
            <small class="w-2/3 text-black-100">
              {{ useGetLocalTime(modelValue.timezone) || '-' }}
            </small>
          </div>
        </div>
      </UiExpansionPanel>
      <UiExpansionPanel
        v-model="interestsPanel"
        title="Lead interests"
        :icon="!tags.length || !!readonly || isPoolLead || isClosed ? '' : 'edit'"
        @action="showEditInterestsSidePanel"
      >
        <div class="mb-5">
          <div v-if="tags.length" class="flex flex-row flex-wrap gap-2">
            <UiTagSecondary
              v-for="(tag, index) in tags"
              :id="`tag_${index}`"
              :key="index"
              :show-tooltip="!!tag.tooltip"
            >
              <template #content>
                {{ tag.text }}
              </template>
              <template #tooltip>
                {{ tag.tooltip }}
              </template>
            </UiTagSecondary>
          </div>
          <UiButtonBase
            v-else-if="!readonly && !isPoolLead && !isClosed"
            id="add_interests"
            class="mt-2 !w-full"
            type="secondary"
            @click="showEditInterestsSidePanel"
            >Add lead interests
          </UiButtonBase>
        </div>
      </UiExpansionPanel>
      <UiExpansionPanel
        v-if="isCommunicationWayPartner && modelValue.partner"
        v-model="partnerDetailsPanel"
        title="Partner details"
      >
        <div class="mb-5 flex flex-col gap-3">
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Name </small>
            <small class="w-2/3 truncate text-black-100">
              {{ partner?.name }}
            </small>
          </div>
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Language </small>
            <small class="w-2/3 text-black-100">
              {{ partner?.language?.text || '-' }}
            </small>
          </div>
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Email </small>
            <small class="w-2/3 truncate text-black-100">
              {{ partner?.email || '-' }}
            </small>
          </div>
        </div>
      </UiExpansionPanel>
      <UiExpansionPanel
        v-else-if="isCommunicationWayRepresentative && modelValue.representative"
        v-model="representativeDetailsPanel"
        title="Representative details"
      >
        <div class="mb-5 flex flex-col gap-3">
          <div class="flex flex-row items-center">
            <small class="w-1/3 text-black-60"> Name </small>
            <small class="w-2/3 truncate text-black-100">
              {{ modelValue.representative?.name }}
            </small>
          </div>
        </div>
      </UiExpansionPanel>
      <UiExpansionPanel v-model="assigneePanel" title="Assignee">
        <div class="mb-4 flex flex-row items-center">
          <small class="w-1/3 text-black-60"> SDR </small>
          <small class="flex w-2/3 items-center gap-2 text-black-100">
            <UiIcon
              v-if="isLeadAssigned && getPreviousAssignee(ROLES.SDR)?.id === assigneeId"
              name="check-filled"
              class="fill-white text-additional-2-120"
            />

            {{ getPreviousAssignee(ROLES.SDR)?.name || '-' }}
          </small>
        </div>
        <div class="flex flex-row items-center">
          <small class="w-1/3 text-black-60"> Agent </small>
          <small class="flex w-2/3 items-center gap-2 text-black-100">
            <UiIcon
              v-if="isLeadAssigned && getPreviousAssignee(ROLES.AGENT)?.id === assigneeId"
              name="check-filled"
              class="fill-white text-additional-2-120"
            />

            {{ getPreviousAssignee(ROLES.AGENT)?.name || '-' }}
          </small>
        </div>
        <div
          v-if="!isSDR(lead.assignee?.role.code) && !isAgent(lead.assignee?.role.code) && isLeadAssigned"
          class="mt-4 flex flex-row items-center"
        >
          <small class="w-1/3 text-black-60"> User </small>
          <small class="flex w-2/3 items-center gap-2 text-black-100">
            <UiIcon name="check-filled" class="fill-white text-additional-2-120" />

            {{ lead.assignee?.name || '-' }}
          </small>
        </div>
      </UiExpansionPanel>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CUSTOM_EVENTS, PIPELINES, STAGES, HIDDEN, POOL_STAGES, ROLES } from '@/constants'
import { POPUPS, SIDE_PANELS } from '@/components/dynamic/maps'
import type { InputItem, Lead, LeadPartner } from '@/types'

import { useUiStore } from '@/store/ui'
import { useAuthStore } from '@/store/auth'
import { isAgent, isSDR } from '~/guards/roles'

const uiStore = useUiStore()
const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue', 'reopen'])

type Props = {
  modelValue: Lead
  readonly?: boolean
}
const props = defineProps<Props>()
const goStage = useCookie('goStage')

const lead = useVModel(props, 'modelValue', emits)

const limitedAccess = computed(() => authStore.getIsSdr && lead.value.pipeline?.code === PIPELINES.QUEUE)
const isSDRTourActive = inject<Ref<boolean>>('isSDRTourActive')

const closedStage = ref()
const isPoolLead = computed(() => Object.values(POOL_STAGES).includes(lead.value.stage?.code as string))

onNuxtReady(async () => await getSalesPipeline())

const getSalesPipeline = async () => {
  try {
    const pipeline = await useGetPipelineByCode(PIPELINES.SALES)
    closedStage.value = pipeline.stages.find((s) => s.code === STAGES.CLOSED)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}
const detailsPanel = ref(true)
const interestsPanel = ref(true)
const partnerDetailsPanel = ref(true)
const representativeDetailsPanel = ref(true)
const assigneePanel = ref(true)
const stagePanel = ref(true)
const contactsPanel = ref(true)
const showTopOverflowBorder = ref(false)
const detailsRef = ref<HTMLElement>()
let contentOverflows = ref(false)

const tags = computedAsync(async () => await useParseInterests(props.modelValue), [])

const isShadowLead = inject<Ref<boolean>>('isShadowLead')
const isClosed = inject<Ref<boolean>>('isClosed')
const isClosedWon = inject<Ref<boolean>>('isClosedWon')
const isClosedLost = inject<Ref<boolean>>('isClosedLost')

const isContactsBlockVisible = computed(() => {
  if (!useGetShowPhoneLead(lead.value)) return false

  if (isShadowLead?.value && (authStore.getIsAdmin || authStore.getIsSalesManager)) return false

  return lead.value.phone_country && lead.value.phone
})

const isLeadAssigned = computed(() => lead.value.assignee?.id)

const isCommunicationWayPartner = inject<Ref<boolean>>('isCommunicationWayPartner')
const isCommunicationWayRepresentative = inject<Ref<boolean>>('isCommunicationWayRepresentative')

const assigneeId = computed(() => {
  return lead.value.assignee?.id
})

const getPreviousAssignee = (role: (typeof ROLES)[keyof typeof ROLES]) => {
  if (!isSDR(role) && !isAgent(role)) {
    throw new Error('Function executed with wrong role')
  }

  if (lead.value.assignee?.role.code === role) {
    return lead.value.assignee
  }

  const key = isSDR(role) ? 'prev_sdr_assignee' : 'prev_agent_assignee'

  if (lead.value[key]?.role.code === role) {
    return lead.value[key]
  }

  return undefined
}

onMounted(() => {
  contentOverflows = computed(() => Number(detailsRef?.value?.scrollHeight) > Number(detailsRef?.value?.offsetHeight))
})
const checkScroll = () => {
  if (contentOverflows.value) showTopOverflowBorder.value = Number(detailsRef.value?.scrollTop) > 0
}

const showEditInterestsSidePanel = () => {
  uiStore.showSidePanel(
    SIDE_PANELS.LEAD_INFO_INTERESTS,
    { leadId: props.modelValue.id },
    {
      input: () => refreshLead(),
    }
  )
}

const showEditDetailsSidePanel = () => {
  uiStore.showSidePanel(
    SIDE_PANELS.LEAD_INFO_DETAILS,
    { leadId: props.modelValue.id },
    {
      input: () => refreshLead(),
    }
  )
}

const showCloseLeadPopup = (leadWon: boolean) => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_CLOSE,
    {
      lead,
      leadWon,
      leadLost: !leadWon,
    },
    {
      lost: () => {
        uiStore.showPopup(
          POPUPS.PIPELINE_LEAD_CLOSE_LOST,
          { lead, stage: closedStage },
          {
            input: () => refreshLead(),
          }
        )
      },
      won: () => {
        uiStore.showSidePanel(
          SIDE_PANELS.PIPELINE_DEAL_CREATE,
          { lead, stage: closedStage },
          {
            input: () => refreshLead(),
          }
        )
      },
    }
  )
}

const showAssignAgentPopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_ASSIGN_AGENT,
    { selectedLeads: [lead.value], qualifying: true },
    {
      input: (user: InputItem) => {
        uiStore.showSnackBanner(`Lead was successfully assigned and moved to ${user?.text} Pipeline.`)
        if (authStore.getIsSdr) {
          return navigateTo('/pipelines/sales')
        } else {
          refreshLead()
        }
      },
    }
  )
}

const refreshLead = () => {
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_HISTORY))
  document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.REFRESH_LEAD_DATA))
}

const manualReturnToQueue = ref(false)

const navigateAfterCreate = (lead: Lead) => {
  navigateTo(`/leads/${lead.id}`, { open: { target: '_blank' } })
}

const createDealForClosed = () => {
  uiStore.showPopup(
    POPUPS.LEAD_CREATE_DEAL_FOR_CLOSED,
    { lead: props.modelValue },
    {
      createDeal: () => {
        uiStore.showSidePanel(
          SIDE_PANELS.PIPELINE_DEAL_CREATE,
          { lead: props.modelValue, stage: closedStage },
          {
            input: () => refreshLead(),
          }
        )
      },
      createLead: () => {
        uiStore.showSidePanel(SIDE_PANELS.PIPELINE_LEAD_CREATE, undefined, {
          created: (lead: Lead) => navigateAfterCreate(lead),

          duplicate: (lead: Lead) => {
            uiStore.showPopup(
              POPUPS.PIPELINE_LEAD_DUPLICATE,
              {
                lead,
              },
              {
                created: (lead: Lead) => navigateAfterCreate(lead),
              }
            )
          },
          suggest: (lead: Lead, duplicateId: string) => {
            uiStore.showPopup(
              POPUPS.PIPELINE_LEAD_DUPLICATE_SUGGEST,
              { lead, duplicateId },
              {
                created: (lead: Lead) => navigateAfterCreate(lead),
              }
            )
          },
        })
      },
    }
  )
}

const returnToQueue = async () => {
  manualReturnToQueue.value = true
  const { error } = await useReturnToQueue(props.modelValue.id)
  if (error.value) {
    uiStore.showSnackBanner(error.value.message, 'error')
  }
  // showReturnToQueuePopup(true)
}

const showReturnToQueuePopup = () => {
  uiStore.showPopup(
    POPUPS.PIPELINE_LEAD_RETURN_TO_QUEUE,
    { manual: manualReturnToQueue.value },
    {
      input: () => redirectToQueueTable(),
    }
  )
}

const redirectToQueueTable = () => {
  if (manualReturnToQueue.value) manualReturnToQueue.value = false
  navigateTo(`/pipelines/queue`)
}

watch(
  () => goStage.value,
  (value) => {
    if (value) {
      setTimeout(() => {
        if (!stagePanel.value) stagePanel.value = true
        if (detailsRef.value && showTopOverflowBorder.value) {
          detailsRef.value.scrollTop = 0
        }
      }, 300)
    }
  }
)

watch(
  () => uiStore.leadMovedToQueue,
  (value) => {
    if (value) showReturnToQueuePopup()
  }
)

const partner = inject<
  LeadPartner & {
    language: InputItem
  }
>('partner')
</script>

<style scoped></style>
