import type { ReleaseResponse } from './releases'
import { useMainStore } from '@/store'
import { useAuthStore } from '@/store/auth'
import { version } from '@/package.json'
import { ENVS, RELEASE_COMPONENTS_CODES } from '@/constants'
import type { ApiResponse } from '~/types'
import { useUiStore } from '~/store/ui'

export const useVersionCheck = async () => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()
  const uiStore = useUiStore()
  const currentEnv = useRuntimeConfig().public.APP_ENV

  type ReleaseResponseComponent = ReleaseResponse['components'][number]

  const { data: FEComponent, error: FEComponentErrors } = await useAPIFetch<ApiResponse<ReleaseResponseComponent>>(
    `/releases/components`,
    {
      query: {
        code: RELEASE_COMPONENTS_CODES.FE_CRM,
      },
    }
  )

  if (FEComponentErrors.value) throw createError(FEComponentErrors.value)

  const { data: BEComponent, error: BEComponentErrors } = await useAPIFetch<ApiResponse<ReleaseResponseComponent>>(
    `/releases/components`,
    {
      query: {
        code: RELEASE_COMPONENTS_CODES.BE_CRM,
      },
    }
  )

  mainStore.setApiVersion(BEComponent.value.data.version)

  // Frontend is not updated
  if (FEComponent.value?.data.version !== version) {
    if (process.env.NODE_ENV !== 'development') {
      uiStore.clearTableFilters()
    }

    // if (!authStore.getIsAdmin && currentEnv !== ENVS.DEV) {
    //   useLogout(true)
    //   return window.location.reload()
    // }
  }

  if (BEComponentErrors.value) throw createError(BEComponentErrors.value)

  return FEComponent.value
}
