<template>
  <div class="flex flex-row justify-between">
    <div class="flex flex-1 flex-col gap-2">
      <div class="flex flex-1 flex-row flex-wrap items-center gap-1">
        <div v-for="(sentence, index) in replacedMessage.split(/(?<=\. )/)" :key="index">
          <div class="flex flex-row items-center gap-2">
            <!-- eslint-disable-next-line -->
            <span v-html="sentence.split('{new_status}')[0]"> </span>
            <div
              v-if="modelValue.body_data && sentence.includes('{new_status}')"
              id="status"
              class="rounded-md px-3 py-1.5 text-center"
              :style="{
                backgroundColor: FINANCE_DEAL_STAGES_COLORS_MAP.get(modelValue.deal_data?.stage?.code),
              }"
            >
              <span class="text-subhead-3">
                {{ modelValue.body_data.new_status }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="modelValue.body_data?.note">
        <div class="text-caption-2 text-black-40">Other notes:</div>
        {{ modelValue.body_data.note }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TimelineItem } from '@/types'
import { FINANCE_DEAL_STAGES_COLORS_MAP } from '~/constants'

type Props = {
  modelValue: TimelineItem
}
const props = defineProps<Props>()

// Replace all the instances of the placeholders in the string with the values from the objects
// E.g. {new_status} is replaced with the new_status value from body_data
const replacePlaceholders = (str: string, obj: any) => {
  return str.replace(/{([^{}]*)}/g, (_, b: string) => {
    if (b === 'new_status') return '{new_status}'
    if (b === 'note') return ''
    return obj[b] || ''
  })
}

const replacedMessage = replacePlaceholders(props.modelValue.body_text, props.modelValue.body_data)
</script>

<style scoped></style>
