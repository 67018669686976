import type { CallId } from '@/types'

export const useGetCallsCredentials = async () => {
  const { data, error } = await useAPIFetch(`/voice-calls/credentials`)
  if (error.value) throw createError(error.value)
  return data.value?.data
}

export const useGetCallsTwillioCredentials = async () => {
  const { data, error } = await useAPIFetch(`/voice-calls/twilio/credentials`)
  if (error.value) throw createError(error.value)
  return data.value?.data
}

export const useGetCallsTwilioToken = async (force: boolean) => {
  const { data, error } = await useAPIFetch(`/voice-calls/twilio/token?force=${force}`)
  if (error.value) throw createError(error.value)
  return data.value?.data
}

export const useToggleVoiceCalls = async (id: number, value: boolean) => {
  const { data, error } = await useAPIFetch(`/users/${id}/voice-calls/switch`, {
    method: 'PATCH',
    body: { is_voice_call_enabled: value },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useVoiceCallStarted = async (body: object) => {
  const { data, error } = await useAPIFetch(`/voice-calls/started`, { method: 'POST', body })
  if (error.value) throw createError(error.value)
  return data.value?.data
}

export const useVoiceCallOutcome = async (callId: CallId, body: object) => {
  await useAPIFetch(`/activities/call/${callId}/outbound`, {
    method: 'POST',
    body,
  })
}

export const useGetLocalNumbers = async (leadId: number) => {
  const { data, error } = await useAPIFetch(`/voice-calls/locals/leads/${leadId}`)
  if (error.value) throw createError(error.value)
  return data.value?.data
}

export const useVerifyPhone = async (body: object) => {
  const { data, error } = await useAPIFetch(`/voice-calls/twilio/phone-verification`, {
    method: 'POST',
    body,
  })
  if (error.value) throw createError(error.value)
  return data.value?.data
}
