import { stringify } from 'qs'
import debounce from 'lodash/debounce'
import type { Activity, ApiResponse, AssignLogsFilters, Lead, PaginationResponse, TimelineItem } from '@/types'

export const useCreateLead = async (leadDTO: Partial<Lead>) =>
  await useAPIFetch<ApiResponse<Lead>>(`/leads/create`, {
    method: 'post',
    body: leadDTO,
  })

export const useGetLead = async (id: number): Promise<Lead> => {
  const { data, error } = await useAPIFetch(`/leads/view/${id}`, {
    method: 'get',
  })

  if (error.value) throw createError(error.value)

  const lead = (data.value as any)?.data

  if (!lead) navigateTo('/login')

  return lead as Lead
}

export const useUpdateLead = async (id: number, lead: Partial<Lead>): Promise<Lead> => {
  const { data, error } = await useAPIFetch(`/leads/${id}`, {
    method: 'put',
    body: lead,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any)?.data
}

export const useReassignLead = async (body: object) => {
  await useAPIFetch('/leads/reassign', {
    method: 'put',
    body,
  })
}
export const useCloseLead = async (id: number, lead: Object): Promise<ApiResponse> => {
  const { data, error } = await useAPIFetch(`/leads/${id}/close`, {
    method: 'PATCH',
    body: lead,
  })
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useArchiveLead = async (id: number, lead: Object): Promise<ApiResponse> => {
  const { data, error } = await useAPIFetch(`/leads/${id}/archive`, {
    method: 'PATCH',
    body: lead,
  })
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useReopenLeads = async (body: Object): Promise<Lead> => {
  const { data, error } = await useAPIFetch(`/leads/bulk/reopen`, {
    method: 'POST',
    body,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useMoveLeadToDormant = async (id: number, lead: Partial<Lead>): Promise<Lead> => {
  const { data, error } = await useAPIFetch(`/leads/${id}/dormant`, {
    method: 'PATCH',
    body: lead,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useMoveLeadToRotation = async (leadId: number) => {
  const { data, error } = await useAPIFetch(`/leads/${leadId}/to-rotation`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useBulkMoveToRotation = async (leadIds: number[], fromQueue?: boolean) => {
  const { data, error } = await useAPIFetch(`/leads/bulk/to-rotation`, {
    method: 'POST',
    body: { lead_ids: leadIds, from_queue: fromQueue },
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useMoveLeadToPool = async (id: number, payload: any) => {
  const { data, error } = await useAPIFetch(`/leads/${id}/to-pool`, {
    method: 'PATCH',
    body: payload,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useMoveLeadToRotationByLanguage = async (leadId: number, languageId: number) => {
  const { data, error } = await useAPIFetch(`/leads/${leadId}/new-rotation-user/${languageId}/language`, {
    method: 'PATCH',
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useBulkMoveLeadToPool = async (body: any) => {
  const { data, error } = await useAPIFetch(`/leads/to-pool`, {
    method: 'PATCH',
    body,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useBulkMoveLeadToRotation = async (languageId: number, body: Record<string, any>) => {
  const { data, error } = await useAPIFetch(`/leads/new-rotation-user/${languageId}/language`, {
    method: 'PATCH',
    body,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useGetLeadActivity = async (id: number, type: string = '', page: number = 1) => {
  const { data, error } = await useAPIFetch(`/leads/${id}/activities`, { params: { type, page } })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetPinnedLeadActivity = async (id: number) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/leads/${id}/activities/pinned`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useCreateLeadActivity = async (activity: Partial<TimelineItem>): Promise<TimelineItem> => {
  const { data, error } = await useAPIFetch(`/activities/create`, {
    method: 'post',
    body: activity,
  })
  if (error.value) throw createError(error.value)
  return data.value as any
}
export const useUpdateLeadActivity = async (activityId: number, body: object): Promise<TimelineItem> => {
  const { data, error } = await useAPIFetch(`/activities/${activityId}`, {
    method: 'PATCH',
    body,
  })
  if (error.value) throw createError(error.value)
  return data.value as any
}

export const useAssignLeadsToAgent = async (
  agentId: string | number,
  leadIds: number[],
  reassignActivities: boolean,
  note?: string,
  fromQueue?: boolean
) => {
  const { data, error } = await useAPIFetch(`/leads/bulk/assign`, {
    method: 'put',
    body: {
      assignee_id: agentId,
      lead_ids: leadIds,
      note,
      from_queue: fromQueue,
      reassign_activities: reassignActivities,
    },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useCheckLeadsAction = async (leadId: number, action: string) => {
  const { data, error } = await useAPIFetch(`/leads/${leadId}/${action}`)
  if (error.value) throw createError(error.value)
  return data.value
}

export const useGetActiveLeads = debounce(
  async (query?: string) => {
    const { data, error } = await useAPIFetch<PaginationResponse>(`/leads/dropdown`, { params: { query } })
    if (error.value) throw createError(error.value)
    return toRaw(data.value)
  },
  200,
  { leading: true, trailing: false }
)

export const useStartedWhatsApp = async (leadId: number) => {
  const { data, error } = await useAPIFetch(`/activities/whatsapp-started/${leadId}`, { method: 'post' })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useCreateShadowLead = async ({ assigneeId, leadId }: { assigneeId: number; leadId: number }) =>
  await useAPIFetch<ApiResponse<Lead>>(`/leads/${leadId}/shadow`, {
    method: 'post',
    body: {
      assignee_id: assigneeId,
    },
  })

export const useViewActivity = async ({ id, isViewed }: { id: Activity['id']; isViewed: boolean }) =>
  await useAPIFetch<ApiResponse<TimelineItem>>(`/activities/${id}/viewed`, {
    method: 'PATCH',
    body: {
      is_viewed: isViewed,
    },
  })

export const useCreateBulkShadowLeads = async (assigneeId: number, leadIds: number[]) =>
  await useAPIFetch<ApiResponse<Lead>>(`/leads/shadow`, {
    method: 'post',
    body: {
      assignee_id: assigneeId,
      lead_ids: leadIds,
    },
  })
export const useGetLeadSources = debounce(
  async (name?: string) => {
    const query = name || ''
    const { data, error } = await useAPIFetch(`/leads/sources?name=${query}`)
    if (error.value) throw createError(error.value)
    return data.value as any
  },
  200,
  { leading: true, trailing: false }
)

export const useAddLeadSource = async (value: string) => {
  const { data, error } = await useAPIFetch('leads/sources', {
    method: 'post',
    body: {
      name: value,
    },
  })
  if (error.value) throw createError(error.value)

  return data.value as any
}

export const useCreateNewDeals = async (leadId: number, value: any[]) => {
  const { data, error } = await useAPIFetch(`finance/leads/${leadId}/deals`, {
    method: 'post',
    body: {
      deals: value,
    },
  })

  if (error.value) {
    throw createError({ message: error.value.message })
  } else {
    return data.value as any
  }
}

// for leads in queue
export const usePickupLead = async (leadId: number) => {
  const data = await useAPIFetch(`pipeline-queue/pickup/${leadId}`, {
    method: 'post',
  })

  return data
}

export const useReturnToQueue = async (leadId: number) =>
  await useAPIFetch(`pipeline-queue/return-to-queue/${leadId}`, {
    method: 'post',
  })

export const useGetAssignLogs = async (query: AssignLogsFilters) => {
  const queryFormatted = stringify(query, { arrayFormat: 'comma', encode: false })
  return await useAPIFetch<PaginationResponse>(`leads/assignment-log?${queryFormatted}`)
}

export const userGetLeadsPartnersIds = async () => await useAPIFetch<ApiResponse>(`/leads/partners/ids`)

export const useQualifyLead = async (assigneeId: string | number, leadId: number, note?: string) => {
  const { data, error } = await useAPIFetch(`/leads/${leadId}/qualify`, {
    method: 'PATCH',
    body: { assignee_id: assigneeId, note },
  })
  if (error.value) throw createError(error.value)
  return data.value
}

export const useSendToQueue = async (body: object) => {
  const { data, error } = await useAPIFetch(`/leads/to-queue`, {
    method: 'PATCH',
    body,
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useCheckBulkActionInQueue = async (code: string, leadIds: number[]) => {
  const { data, error } = await useAPIFetch(`/pipeline-queue/bulk/check/${code}`, {
    method: 'POST',
    body: { lead_ids: leadIds },
  })
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}
